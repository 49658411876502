import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import { useTheme } from '@emotion/react';
import { Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { useAuthentication } from '~/store';
import { MobileNavigationProps } from './mobile-navigation';
import { Text } from '$components/elements/text';
import { BOISvg, SvgIcon } from '$components/elements/svg-icon';
import { Logo } from '$components/elements/logo';
import { Button } from '$components/elements/button';
import { MiniBasket } from '$components/elements/mini-basket';
import { Link } from '$components/elements/link';

type IMenuProps = {
    menuOpen: () => void;
} & MobileNavigationProps;

export const MobileMenu: FC<IMenuProps> = ({ menu, menuOpen: onMenuOpen }: IMenuProps) => {
    const { translate } = useTranslation();
    const theme = useTheme();
    const { data: session } = useSession();
    const { setShowMemberAccessPanel } = useAuthentication();

    return (
        <MobileMenuContainer
            alignItems="center"
            gap="small"
            justifyContent="space-between"
            css={{ width: '100%', minHeight: theme.sizes.mobileMenuHeight }}
        >
            <Flex alignItems="center" css={{ gap: 16 }}>
                <LinkText onClick={onMenuOpen} title={translate('menu.menu')}>
                    <Flex column alignItems="center">
                        <SvgIcon size={20} svg="list" />
                    </Flex>
                </LinkText>

                <Flex column alignItems="center">
                    <Logo size="large" />
                </Flex>
            </Flex>
            <Flex gap="small" alignItems="center" justifyContent="flex-end">
                {!!menu.findStore?.path && !!menu.findStore?.title && (
                    <MenuLink href={menu.findStore.path} svg="pin1" text={menu.findStore.title} />
                )}
                {session && !!menu.myPageHome?.path ? (
                    <MenuLink href={menu.myPageHome.path} svg="userAlt" text={menu.myPageHome.title || ''} />
                ) : (
                    <Button variant="custom" onClick={() => setShowMemberAccessPanel('login')}>
                        <Flex column alignItems="center">
                            <SvgIcon size={theme.sizes.menuIcons} svg="userAlt" />
                            <Text
                                font="regular"
                                fontWeight="Medium"
                                style={{ whiteSpace: 'nowrap' }}
                                size={theme.fontSizes.xxxs}
                            >
                                {translate('loginPage.login')}
                            </Text>
                        </Flex>
                    </Button>
                )}

                <MiniBasket />
            </Flex>
        </MobileMenuContainer>
    );
};

const MobileMenuContainer = styled(Flex)(({ theme: { space, sizes } }) => ({
    position: 'relative',
    padding: `${sizes.siteGutter} ${space[2]} 0`,
}));

const MenuLink: FC<{ svg: keyof typeof BOISvg; href: string; text: string }> = ({
    svg,
    href,
    text,
}: {
    svg: keyof typeof BOISvg;
    href: string;
    text: string;
}) => {
    const theme = useTheme();

    return (
        <Link href={href}>
            <Flex column alignItems="center">
                <SvgIcon size={theme.sizes.menuIcons} svg={svg} />
                <Text font="regular" fontWeight="Medium" style={{ whiteSpace: 'nowrap' }} size={theme.fontSizes.xxxs}>
                    {text}
                </Text>
            </Flex>
        </Link>
    );
};

const LinkText = styled.button({
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});
