import React, { FC } from 'react';
import { ifProp } from 'styled-tools';
import styled from '@emotion/styled';

type Props = {
    className?: string;
    active?: boolean;
    highlight?: boolean;
    borderless?: boolean;
};

export const MobileSubMenuItem: FC<React.PropsWithChildren<Props>> = ({
    children,
    highlight,
    className,
    active,
    borderless,
}: React.PropsWithChildren<Props>) => {
    return (
        <StyledListItem active={active} highlight={highlight} className={className} borderless={borderless}>
            {children}
        </StyledListItem>
    );
};

const StyledListItem = styled.li<Partial<Props>>(
    ({ theme }) => ({
        position: 'relative',
        borderBottom: `1px solid ${theme.colors.primaryGreyLight}`,
    }),
    ifProp({ active: true }, ({ theme, highlight }) => ({
        highlight: highlight ? theme.colors.gift : 'inherit',
        borderLeft: theme.general.activeMenuBorder,
    })),
    ifProp('borderless', {
        borderBottom: 'none',
    })
);
