import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { motion } from 'framer-motion';
import { IMenuItem, INavigation } from '$models';
import { Flex } from '$components/layouts';
import { ActiveLinkFunc, NavigationLink } from '../shared/navigation-link';
import { MobileSubMenuItem } from './mobile-sub-menu-item';
import { useTranslation } from '$hooks';
import { absoluteCenter } from '$lib/style-helpers';
import { transition, variants } from './mobile-sub-menu.animation';
import { Text } from '$components/elements/text';
import { SvgIcon } from '$components/elements/svg-icon';
import { Button } from '$components/elements/button';
import { Link } from '$components/elements/link';
import { Logo } from '$components/elements/logo';

type ISubMenuProps = {
    menuClose: () => void;
    goBack?: () => void;
    setMenu: (menu: IMenuItem) => void;
    menu: IMenuItem;
    mainMenu: INavigation;
};

const ANIMATION_DELAY = 200;

export const MobileSubMenu: FC<ISubMenuProps> = ({ menu, mainMenu, menuClose, goBack, setMenu }: ISubMenuProps) => {
    const { translate } = useTranslation();
    const [subMenuState, setSubMenuState] = useState<{ menuItem?: IMenuItem; direction: keyof typeof variants }>({
        direction: 'initial',
    });
    const theme = useTheme();

    useEffect(() => {
        if (subMenuState.direction === 'initial') {
            return;
        }

        const timeout = setTimeout(() => {
            if (subMenuState.menuItem && subMenuState.direction === 'forward') {
                setMenu(subMenuState.menuItem);
            } else if (subMenuState.direction === 'backward') {
                goBack?.();
            }

            setSubMenuState({ direction: 'initial' });
        }, ANIMATION_DELAY);

        return () => clearTimeout(timeout);
    }, [subMenuState]);

    return (
        <StyledList>
            <SubMenuHeader>
                <Flex alignItems="center" justifyContent="flex-end">
                    <PositionedLogo />
                    <LinkButton onClick={menuClose} variant="custom">
                        <SvgIcon size={theme.sizes.menuIcons} svg="cross" />
                    </LinkButton>
                </Flex>
            </SubMenuHeader>

            <motion.div
                variants={variants}
                animate={subMenuState.direction}
                transition={transition(
                    subMenuState.direction === 'forward' || subMenuState.direction === 'backward' ? 0.2 : 0
                )}
                initial="initial"
            >
                {!!goBack && (
                    <MobileSubMenuItem>
                        <LinkButton onClick={() => setSubMenuState({ direction: 'backward' })} variant="custom">
                            <Flex grow>
                                <SvgIcon size={theme.sizes.menuIcons} svg="chevronLeft" />
                            </Flex>
                            <StyledHeader>{menu.text}</StyledHeader>
                            <Flex grow />
                        </LinkButton>
                    </MobileSubMenuItem>
                )}
                <SubMenuChildren>
                    {!!menu.url && (
                        <MobileSubMenuItem>
                            <NavigationLink href={menu.url} target={menu.urlTarget}>
                                <LinkTextHref size={theme.fontSizes.default} fontWeight="Bold">
                                    {`${translate('menu.seeAll')} ${menu.text?.toLowerCase()}`}
                                </LinkTextHref>
                            </NavigationLink>
                        </MobileSubMenuItem>
                    )}
                    {!!menu.children?.length && (
                        <>
                            {menu.children?.map(
                                (m) =>
                                    !!m.url && (
                                        <React.Fragment key={`${m.url}`}>
                                            {m.children?.length ? (
                                                <MobileSubMenuItem>
                                                    <LinkButton
                                                        onClick={() =>
                                                            setSubMenuState({ menuItem: m, direction: 'forward' })
                                                        }
                                                        variant="custom"
                                                    >
                                                        <Text size={theme.fontSizes.default} fontWeight="Medium">
                                                            {m.text}
                                                        </Text>

                                                        <SvgIcon size={theme.sizes.menuIcons} svg="chevronRight" />
                                                    </LinkButton>
                                                </MobileSubMenuItem>
                                            ) : (
                                                <MobileSubMenuItem active={false}>
                                                    <NavigationLink href={m.url} target={m.urlTarget}>
                                                        <LinkTextHref
                                                            size={theme.fontSizes.default}
                                                            fontWeight="Medium"
                                                        >
                                                            {m.text}
                                                        </LinkTextHref>
                                                    </NavigationLink>
                                                </MobileSubMenuItem>
                                            )}
                                        </React.Fragment>
                                    )
                            )}
                            <Divider />
                        </>
                    )}

                    {!!menu.linkList?.length && (
                        <>
                            {menu.linkList?.map(
                                (m) =>
                                    !!m.destinationUrl && (
                                        <React.Fragment key={m.destinationUrl}>
                                            <NavLink
                                                url={m.destinationUrl}
                                                target={m.destinationTarget}
                                                text={m.linkText}
                                            />
                                        </React.Fragment>
                                    )
                            )}
                            <Divider />
                        </>
                    )}

                    {mainMenu.giftShop?.path && (
                        <MobileSubMenuItem borderless>
                            <StyledLink href={mainMenu.giftShop.path}>
                                <SvgIcon size={theme.sizes.menuIcons} svg="gift" />
                                <StyledText>{mainMenu.giftShop.title}</StyledText>
                            </StyledLink>
                        </MobileSubMenuItem>
                    )}
                    {mainMenu.findStore?.path && (
                        <MobileSubMenuItem borderless>
                            <StyledLink href={mainMenu.findStore.path}>
                                <SvgIcon size={theme.sizes.menuIcons} svg="pin1" />
                                <StyledText>{mainMenu.findStore.title}</StyledText>
                            </StyledLink>
                        </MobileSubMenuItem>
                    )}
                </SubMenuChildren>
            </motion.div>
        </StyledList>
    );
};
const NavLink: FC<{ url: string; target?: string; text?: string }> = ({
    url,
    target,
    text,
}: {
    url: string;
    target?: string;
    text?: string;
}) => {
    const theme = useTheme();

    return (
        <NavigationLink href={url} target={target}>
            {({ active }: ActiveLinkFunc) => (
                <MobileSubMenuItem active={active}>
                    <LinkTextHref size={theme.fontSizes.default} fontWeight="Medium">
                        {text}
                    </LinkTextHref>
                </MobileSubMenuItem>
            )}
        </NavigationLink>
    );
};

const SubMenuChildren = styled.ul({
    overflowY: 'auto',
});

const StyledHeader = styled.h6(({ theme }) => ({
    margin: 0,
    lineHeight: theme.lineHeights.base,
    color: theme.colors.primaryTheme,
    whiteSpace: 'pre',
}));

const StyledList = styled.ul(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
    padding: `0 ${theme.space[5]}`,
}));

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'flex',
    whiteSpace: 'nowrap',
    padding: `${theme.space[4]} 0`,
}));

const StyledText = styled.span(({ theme }) => ({
    paddingLeft: theme.space[3],
}));

const LinkButton = styled(Button)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.space[5]} 0`,
    borderRadius: 'initial',
}));

const LinkTextHref = styled(Text)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.space[5]} 0`,
}));

const SubMenuHeader = styled(MobileSubMenuItem)(({ theme }) => ({
    borderBottom: `2px solid ${theme.colors.black}`,
    position: 'relative',
}));

const PositionedLogo = styled(Logo)({
    ...absoluteCenter,
});

const Divider = styled.div(({ theme }) => ({
    borderBottom: `2px solid ${theme.colors.black}`,
}));
