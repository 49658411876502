import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import * as Dialog from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { ifProp } from 'styled-tools';
import { Button } from '$components/elements/button';
import { Flex } from '$components/layouts';
import { SearchField, SearchSuggestions } from '$components/elements/search';
import { DEFAULT_SORTING_HELLO_RETAIL, defaultHelloRetailSortOption } from '~/modules/filter/helpers';
import { SvgIcon } from '$components/elements/svg-icon';
import { useTranslation } from '$hooks';
import { SearchContentTypes } from '~/modules/search';
import { useSearchSuggestionFilter } from '~/modules/search/hooks/use-search-suggestion-filter';
import { useCombinedSearchSuggestion } from '~/modules/search/combined-suggestion/use-combined-search-suggestion';
import { theme } from '$theme';
import { IHelloRetailSelectedFacet, IHelloRetailSortingOption } from '~/modules/search/interfaces/suggestion';
import { useSearchHistory } from '~/modules/search/hooks/use-search-history.hook';

type Props = {
    open?: boolean;
    onSetOpen?: (open: boolean) => void;
    onDismiss?: () => void;
    // eslint-disable-next-line react/no-unused-prop-types
    searchResultUrl?: string;
};

export const MobileSearch: FC<Props> = memo(({ open, onSetOpen, onDismiss }: Props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [inputFocus, setInputFocus] = useState(false);
    const [currentTab, setCurrentTab] = useState('products');
    const [queryHandlingReady, setQueryHandlingReady] = useState(false);
    const [initialFacets, setInitialFacets] =
        useState<{ sorting: string; selectedFacets: IHelloRetailSelectedFacet[]; size?: number }>();
    const { query, isReady } = useRouter();
    const inputRef = useRef<HTMLInputElement>(null);
    const {
        data,
        articleResults,
        storeResults,
        categoryResults,
        isLoading,
        initialContent,
        initialContentTitle,
        zeroResultData,
        zeroResultTitle,
        searchWordResults,
        resultCount,
        onLoadMore,
        onSetFacets,
        productFacets,
        sortingOptions,
        loadingNextPage,
        onResetSize,
        serieResults,
        clearSuggestionQuery,
    } = useCombinedSearchSuggestion(searchTerm, {
        searchType: SearchContentTypes.Products,
        overlayOpen: open,
        enableQueryHandling: !query.slug?.includes('search') && queryHandlingReady,
        currentTab,
        initialFacets,
    });
    const { facetPanelOpen, selectedFacets, setFacetPanelOpen, sortingOptionsWithDefault } = useSearchSuggestionFilter({
        facets: productFacets,
        sortingOptions,
    });
    const { onAddHistoryItem } = useSearchHistory();

    // const resultUrl = `${searchResultUrl}?Search=${encodeURIComponent(searchTerm)}&Sort=${SORTING_RELEVANCE}`;

    useEffect(() => {
        if (query.Search && query.Search !== searchTerm && query?.slug?.includes('search')) {
            setSearchTerm(query.Search.toString());
        }
        if (!query.slug?.includes('search')) {
            setSearchTerm('');
        }
    }, [query]);

    useEffect(() => {
        if (isReady && !queryHandlingReady) {
            if (query.openSearchSuggestion) {
                onSetOpen?.(true);
                if (query.suggestionSearch) {
                    setSearchTerm(query.suggestionSearch.toString());
                }
                if (query.suggestionTab) {
                    setCurrentTab(query.suggestionTab.toString());
                }
                const queryFacets = { ...query };
                delete queryFacets.openSearchSuggestion;
                delete queryFacets.suggestionTab;
                delete queryFacets.suggestionSearch;
                const sorting = queryFacets.suggestionSorting;
                delete queryFacets.suggestionSorting;
                let size = undefined;
                if (queryFacets.suggestionPageSize) {
                    size = parseInt(queryFacets.suggestionPageSize.toString());
                }
                delete queryFacets.suggestionPageSize;
                const mappedFacets: IHelloRetailSelectedFacet[] = [];

                Object.entries(queryFacets).forEach(([key, value]) => {
                    if (Array.isArray(value) && value.length >= 1) {
                        value.forEach((v) => {
                            mappedFacets.push({
                                key: `${key.toString()}:${v?.toString()}`,
                                title: v?.toString(),
                                query: `${key.toString()}:${v?.toString()}`,
                            });
                        });
                    } else {
                        mappedFacets.push({
                            key: `${key.toString()}:${value?.toString()}`,
                            title: value?.toString(),
                            query: `${key.toString()}:${value?.toString()}`,
                        });
                    }
                });
                setInitialFacets({
                    sorting: sorting?.toString(),
                    selectedFacets: mappedFacets,
                    size: size,
                });
            }
            setQueryHandlingReady(true);
        } else if (isReady && queryHandlingReady && !query?.openSearchSuggestion && !!query?.slug?.length) {
            setQueryHandlingReady(false);
        }
    }, [isReady, query]);

    const { translate } = useTranslation();

    const onSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (!searchTerm) {
            return;
        }
        onAddHistoryItem(searchTerm);
        inputRef.current?.blur();
        // searchResultUrl && router.push(resultUrl);
    };

    const onReset = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.stopPropagation();
        setSearchTerm('');
        onSetFacets?.({ sorting: defaultHelloRetailSortOption });
    };

    function onMobileLinkSearch(searchQuery: string) {
        setSearchTerm(searchQuery);
        onSubmit();
    }

    const activeFacets = useMemo(() => {
        let count = 0;

        if (selectedFacets.selectedFacets?.length) {
            count += selectedFacets.selectedFacets.length;
        }
        if (selectedFacets.sorting?.query && selectedFacets.sorting?.query !== DEFAULT_SORTING_HELLO_RETAIL) {
            count += 1;
        }
        if (sortingOptions?.length) {
            const selectedSorting = sortingOptions.find((option: IHelloRetailSortingOption) => option.selected);
            if (selectedSorting && selectedSorting.query !== DEFAULT_SORTING_HELLO_RETAIL) {
                count += 1;
            }
        }
        return count;
    }, [selectedFacets, sortingOptions]);

    return (
        <Dialog.Root open={open}>
            <StyledDialogContent
                onOpenAutoFocus={() => setInputFocus(true)}
                onCloseAutoFocus={() => setInputFocus(false)}
            >
                <StyledSearchFieldWrapper alignItems="center">
                    <StyledIconButton
                        variant="custom"
                        onClick={() => {
                            onDismiss?.();
                            clearSuggestionQuery();
                        }}
                    >
                        <AccessibleIcon.Root
                            label={translate('search.headerSearch.backStyledIconButtonAccessibilityLabel')}
                        >
                            <SvgIcon svg="arrowLeft" size="md" />
                        </AccessibleIcon.Root>
                    </StyledIconButton>
                    {/* <SearchFieldForm onSubmit={(event) => onSubmit?.(event)}> */}
                    <SearchField
                        ref={inputRef}
                        searchTerm={searchTerm}
                        onChange={(value: string) => setSearchTerm(value)}
                        focus={inputFocus}
                        variant="mobile"
                        onSubmit={onSubmit}
                        onReset={onReset}
                        onMobileEnter={() => onAddHistoryItem(searchTerm)}
                        isMobile
                    />
                    {/* </SearchFieldForm> */}
                    {!!searchTerm && !!data?.length && (
                        <StyledFilterButton
                            variant="custom"
                            onClick={() => setFacetPanelOpen(true)}
                            disabled={currentTab !== 'products'}
                        >
                            <SvgIcon
                                svg="equalizer"
                                size={22}
                                color={currentTab === 'products' ? theme.colors.textDefault : theme.colors.grey50}
                            />
                            {!!activeFacets && currentTab === 'products' && (
                                <ActiveFacetCount>{activeFacets}</ActiveFacetCount>
                            )}
                        </StyledFilterButton>
                    )}
                </StyledSearchFieldWrapper>

                <StyledSuggestionWrapper>
                    <SearchSuggestions
                        variant="plain"
                        productResults={data}
                        articleResults={articleResults}
                        storeResults={storeResults}
                        categoryResults={categoryResults}
                        searchWordResults={searchWordResults}
                        initialContent={initialContent}
                        initialContentTitle={initialContentTitle}
                        zeroResultContent={zeroResultData}
                        zeroResultContentTitle={zeroResultTitle}
                        isLoading={isLoading}
                        isSearching={!!searchTerm}
                        searchTerm={searchTerm}
                        closeOverlay={() => {
                            onDismiss?.();
                            setFacetPanelOpen(false);
                        }}
                        resultCount={resultCount}
                        facets={productFacets}
                        selectedFacets={selectedFacets}
                        sortingOptions={sortingOptionsWithDefault}
                        filterPanelOpen={facetPanelOpen}
                        setFilterPanelOpen={setFacetPanelOpen}
                        onSetFacets={onSetFacets}
                        onLoadMore={onLoadMore}
                        loadingNextPage={loadingNextPage}
                        onResetSize={onResetSize}
                        serieResults={serieResults}
                        onSetCurrentTab={setCurrentTab}
                        withScrollRestoration
                        hasActiveFacets={!!activeFacets}
                        initialTab={currentTab}
                        onMobileLinkSearch={onMobileLinkSearch}
                    />
                </StyledSuggestionWrapper>
            </StyledDialogContent>
        </Dialog.Root>
    );
});

const StyledDialogContent = styled(Dialog.Content)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.colors.white,
    animation: `${slideUpAndFade} 0.2s ease-out`,
    zIndex: theme.zIndices.modal,
}));

const StyledSearchFieldWrapper = styled(Flex)(({ theme: { colors, space } }) => ({
    borderBottom: `1px solid ${colors.borderColor}`,
    padding: `${space[2]} ${space[3]}`,
}));

const StyledSuggestionWrapper = styled.div(({ theme: { space } }) => ({
    marginBottom: space[7],
}));

const StyledIconButton = styled(Button)(({ theme: { space } }) => ({
    padding: `${space?.[3]} ${space?.[2]} ${space?.[3]} 0`,
}));

const StyledFilterButton = styled(Button)(
    ({ theme: { space } }) => ({
        position: 'relative',
        marginLeft: space[4],
    }),

    ifProp('disabled', {
        backgroundColor: 'transparent',
        cursor: 'auto',

        '&:hover': {
            backgroundColor: 'transparent',
        },
    })
);

const ActiveFacetCount = styled.div(({ theme: { colors, general, fontSizes } }) => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: -8,
    top: -8,
    backgroundColor: colors.red,
    borderRadius: general.circleRadius,
    fontSize: fontSizes.xxs,
    color: colors.white,
    width: '16px',
    height: '16px',
}));

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});
