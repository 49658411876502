import React, { FC } from 'react';
import { useRouter } from 'next/router';
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import styled from '@emotion/styled';
import { getColor } from '~/lib/get-color';

export type LinkProps = NextLinkProps & {
    target?: string;
    color?: string;
    activeClass?: string;
    bgColor?: string;
    className?: string;
    container?: boolean;
    style?: React.CSSProperties;
    onMouseEnter?: () => void;
};

export type ActiveLinkFunc = {
    active: boolean;
};

export const NavigationLink: FC<React.PropsWithChildren<LinkProps>> = ({
    children,
    ...props
}: React.PropsWithChildren<LinkProps>) => {
    const { asPath } = useRouter();

    if (!children) {
        return null;
    }

    const active = asPath === props.href;

    const contents: React.ReactNode = typeof children === 'function' ? children({ active }) : children;

    return (
        <NextLink {...props} passHref={true}>
            {props.container ? (
                contents
            ) : (
                <StyledLink
                    style={props.style}
                    color={props.color}
                    bgColor={props.bgColor}
                    onMouseEnter={props.onMouseEnter}
                    target={props.target}
                >
                    {contents}
                </StyledLink>
            )}
        </NextLink>
    );
};

export const StyledLink = styled.a<{ color?: string; bgColor?: string }>(({ theme, color, bgColor }) => ({
    color: getColor(theme, color) || theme.colors.black,
    backgroundColor: getColor(theme, bgColor) || 'transparent',
}));
