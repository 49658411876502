export const variants = {
    forward: { opacity: 0, x: -150 },
    initial: { opacity: 1, x: 0 },
    backward: { opacity: 0, x: 150 },
};

export const transition = (xDuration: number) => ({
    delay: 1,
    x: {
        type: 'tween',
        duration: xDuration,
    },
    opacity: { type: 'easeOut', duration: 0.2 },
});
