import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import * as RadixDialog from '@radix-ui/react-dialog';
import { useRouter } from 'next/router';
import { keyframes } from '@emotion/react';
import { IMenuItem, INavigation } from '$models';
import { MobileMenu } from './mobile-menu';
import { MobileSubMenu } from './mobile-sub-menu';
import { MobileSearch } from './mobile-search';
import { Container } from '$components/layouts';
import { RouteKeys, useCMSRoute } from '~/store';
import { NoSSR } from '$components/elements/no-ssr';
import { SearchField } from '$components/elements/search';

export type MobileNavigationProps = {
    menu: INavigation;
    className?: string;
};

export const MobileNavigation: FC<MobileNavigationProps> = ({ menu, className }: MobileNavigationProps) => {
    const router = useRouter();
    const [showMenu, setShowMenu] = useState(false);
    const [subMenu, setSubMenu] = useState<IMenuItem[]>([
        {
            children: [...(menu.mainMenuItems || [])],
        },
    ]);

    const [showSearch, setShowSearch] = useState(false);
    const { routes } = useCMSRoute();

    useEffect(() => {
        const handleRouteChange = () => {
            setShowSearch(false);
            setShowMenu(false);
        };
        router.events.on('routeChangeStart', handleRouteChange);

        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router]);

    return (
        <Navigation className={className}>
            <Container>
                <Header>
                    <MobileMenu menu={menu} menuOpen={() => setShowMenu(true)} />
                    <SearchField
                        onFocus={({ target }) => {
                            setShowSearch(true);
                            target && target.blur();
                        }}
                        onlyVisual
                    />
                </Header>
                <RadixDialog.Root open={showMenu} onOpenChange={setShowMenu}>
                    <SideMenuBackdrop />
                    <SideMenuContainer>
                        {!!subMenu.length && (
                            <MobileSubMenu
                                mainMenu={menu}
                                menu={subMenu[subMenu.length - 1]}
                                goBack={
                                    subMenu.length > 1
                                        ? () => setSubMenu(subMenu.filter((_x, i) => i < subMenu.length - 1))
                                        : undefined
                                }
                                setMenu={(menu) => setSubMenu([...subMenu, menu])}
                                menuClose={() => setShowMenu(false)}
                            />
                        )}
                    </SideMenuContainer>
                </RadixDialog.Root>
                <NoSSR>
                    {routes[RouteKeys.Search] && (
                        <MobileSearch
                            searchResultUrl={routes[RouteKeys.Search]}
                            open={showSearch}
                            onSetOpen={(open) => setShowSearch(open)}
                            onDismiss={() => setShowSearch(false)}
                        />
                    )}
                </NoSSR>
            </Container>
        </Navigation>
    );
};

const Navigation = styled.nav(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: theme.sizes.mobileMenuHeight,
    justifyContent: 'center',
}));

const Header = styled.div({
    display: 'flex',
    flexDirection: 'column',
});

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
});

const contentShow = keyframes({
    '0%': { transform: 'translate(-100%)' },
    '100%': { transform: 'translate(0)' },
});

const SideMenuBackdrop = styled(RadixDialog.Overlay)({
    backgroundColor: 'rgba(0,0,0, 0.6)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
});

const SideMenuContainer = styled(RadixDialog.Content)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    borderLeft: 0,
    bottom: 0,
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    maxWidth: '100vw',
    position: 'fixed',
    top: 0,
    width: theme.sizes.mobileMenuWidth,
    '@media (prefers-reduced-motion: no-preference)': {
        animation: `${contentShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,
        willChange: 'transform',
    },
    '&:focus': { outline: 'none' },
}));
